.container {
	width: 100%;
	/*
  max-height: 620px;
  overflow-y: scroll; */
	min-width: 565px;
	max-height: 71vh;
	overflow-y: scroll;
}

.icon_container_wrap,
.button_container,
.order_details_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
	padding: 30px 30px 30px 30px;
}

.icon_container_wrap {
	gap: 0px;
}

.button_container {
	background-color: #ffffff;
	flex-direction: row;
	justify-content: center;
	gap: 1rem;
	width: 100%;
	padding: 30px 30px 30px 30px;
	position: sticky;
	bottom: 0;
}

.order_details_container {
	padding: 30px 30px 0px 30px;
}

.content_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.content_container h1 {
	font-size: 24px;
	font-weight: 700;
	line-height: 150%; /* 36px */
	color: var(--Greyscale-900, #111827);
	text-align: center;
	margin-top: -20px;
}
.content_container p {
	font-size: 14px;
	font-weight: 500;
	line-height: 160%; /* 22.4px */
	color: var(--Greyscale-600, #718096);
	text-align: center;
}

.order-success {
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
}
.order-success-top {
	width: 100%;
	padding: 0 0 20px;
}
.order-success-top .order-success-graphics {
	width: 250px;
	height: 250px;
	margin: 0 auto;
	margin-bottom: -55px;
}
.order-success-top .order-success-graphics svg {
	width: 100%;
	height: 100%;
	display: block;
}
.order-success-top h2 {
	font-size: 16px;
	font-weight: 600;
	margin: 0 0 10px;
	line-height: 24px;
}
.order-success-top p {
	margin: 0;
	font-size: 14px;
	line-height: 22.4px;
}

.order-success-bottom .order-info {
	gap: 10px;
	display: flex;
	font-size: 12px;
	margin: 0 0 25px;
	line-height: 19.2px;
	flex-direction: column;
}
.order-success-bottom .order-info .order-id,
.order-success-bottom .order-info .order-item,
.order-success-bottom .order-info .order-sample-collection,
.order-success-bottom .order-info .order-confirmation {
	display: flex;
	gap: 15px;
}
.order-success-bottom .order-info .order-id div:first-child,
.order-success-bottom .order-info .order-item div:first-child,
.order-success-bottom .order-info .order-sample-collection div:first-child,
.order-success-bottom .order-info .order-confirmation div:first-child {
	color: var(--Greyscale-900, #111827);
	font-size: 16px;
	font-weight: 500;
	line-height: 160%; /* 25.6px */
}

.order-success-bottom .order-info .order-id div:last-child,
.order-success-bottom .order-info .order-item div:last-child,
.order-success-bottom .order-info .order-sample-collection div:last-child,
.order-success-bottom .order-info .order-confirmation div:last-child {
	color: var(--Greyscale-600, #718096);
	font-size: 14px;
	font-weight: 400;
	line-height: 160%; /* 22.4px */
}

.order-success-bottom .order-success-actions {
	gap: 10px;
	display: flex;
}
.order-success-bottom .order-success-actions div {
	display: block;
	text-align: center;
	width: 100%;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 20px;
	border-radius: 6px;
	line-height: 22.4px;
	border: 1px solid;
}
.order-success-bottom .order-success-actions div:first-child {
	background: rgba(254, 150, 74, 0.1);
}
