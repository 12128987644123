.testCard_patient_select {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
	padding: 12px 20px;
	border-radius: 6px;
	border: 1px solid var(--greyscale-300, #eeeff2);
	color: var(--ltPrimary);
	cursor: pointer;
	text-wrap: nowrap;
	margin-left: auto;
	max-height: 44px;
}

.testCard_patient_select.patient_1 {
	padding: 12px 26px;
}

.testCard_patient_select.fullWidth {
	width: 100%;
}

.testCard_patient_select.lg {
	padding: 12px 56px;
}
.testCard_patient_select.patient_1.lg {
	padding: 12px 60px;
}

.testCard_patient_select.sm {
	padding: 8px 26px;
}
.testCard_patient_select.patient_1.sm {
	padding: 8px 30px;
}

.testCard_patient_select.xs {
	padding: 3px 8px;
}
.testCard_patient_select.patient_1.xs {
	padding: 3px 12px;
}

.testCard_patient_select.xl {
	padding: 16px 56px;
}
.testCard_patient_select.patient_1.xl {
	padding: 16px 56px;
}

.testCard_patient_select.active {
	border: 1px solid var(--ltPrimary);
	color: var(--ltPrimary);
}
.testCard_patient_select.error {
	border: 1px solid var(--ltErrorBase);
}

/* Media Query */

@media (min-width: 768px) and (max-width: 1200px) {
	.testCard_patient_select {
		padding: 12px 13.2px;
		max-height: 39px;
	}

	.testCard_patient_select p {
		font-size: 12px;
	}

	.testCard_patient_select.patient_1 {
		padding: 12px 17.2px;
	}
	.testCard_patient_select.lg {
		padding: 12px 26px;
	}
	.testCard_patient_select.patient_1.lg {
		padding: 12px 30px;
	}
	.testCard_patient_select.sm {
		padding: 8px 10px;
	}
	.testCard_patient_select.patient_1.sm {
		padding: 8px 14px;
	}
	.testCard_patient_select.xs {
		padding: 3px 2px;
	}
	.testCard_patient_select.patient_1.xs {
		padding: 3px 2px;
	}
	.testCard_patient_select.xl {
		padding: 16px 26px;
	}
	.testCard_patient_select.patient_1.xl {
		padding: 16px 30px;
	}
}
