.dialog_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px;
	color: #111827;
}
.dialog_head i {
	color: #718096;
	cursor: pointer;
}
