// components/CouponCodeInput.module.scss
@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.container {
	display: flex;
	border: 1px solid color(grey300);
	// border-radius: 10px;
	border-radius: 10px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}

.iconContainer {
	display: flex;
	align-items: center;
	padding: 10px;
}
.icon {
	width: 20px;
	height: 20px;
	color: getColor(grey500);
}

.input {
	flex: 1;
	border: none;
	padding: 8px 10px;
	outline: none;
	font-size: 14px;
}

.button {
	padding: 12px 20px;
	border: none;
	background-color: getColor(primary);
	border-radius: 0 10px 10px 0; // Round the right corners only
	color: white;
	font-size: 14px;
	cursor: pointer;
	transition: background-color 0.3s;
	height: 100%;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 0;

	&:hover {
		background-color: #038f5b;
	}
}
