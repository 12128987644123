.input_wrap_label {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-bottom: 10px;
}
.input_wrap_label span {
	font-size: 24px;
	color: #fd6a6a;
	align-self: center;
	height: 24px;
}
.label {
	color: var(--900, #1f2937);
}
