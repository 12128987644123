.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 48px;
	height: 48px;
	background: #e8f5f5;
	color: var(--ltPrimary);
	overflow: hidden;
	border-radius: 30px;
}
