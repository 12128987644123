@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.sticky__cart {
	position: relative;

	.sticky__box {
		position: fixed;
		top: 40%;
		right: 0;
		height: 75px;
		min-width: 70px;
		transition: 0.1s ease-in-out;
		cursor: pointer;
		box-shadow: 0 0 8px -1px rgb(0 0 0 / 30%);

		opacity: 1;
		z-index: 9999999999;
		border-radius: 10px;
		&:hover {
			opacity: 1;
		}
		.sticky__box__top {
			height: 50px;
			width: 100%;
			display: flex;
			flex-direction: column;
			padding: 5px 14px;
			color: getColor(white);
			background-color: getColor(primary);
			justify-content: center;
			font-size: 12px;
			font-weight: 700;
			align-items: center;
			border-radius: 10px 0px 0 0;
		}
		.sticky__box__bottom {
			height: 25px;
			width: 100%;
			background: getColor(orange);
			display: flex;
			color: white;
			justify-content: center;
			font-size: 12px;
			font-weight: 700;
			align-items: center;
			border-radius: 0 0 0px 10px;
		}
	}

	.sticky__drawer {
		position: fixed;
		right: -375px; /* Start position outside the screen */
		transition: right 0.3s ease;
		z-index: 99999999999;
		width: 375px;
		height: 100%;
		&.open {
			// position: fixed;
			right: 0;
		}
		.drawer__header {
			position: relative;
			height: 56px;
			width: 100%;
			background-color: getColor(white);
			border-radius: 0px 0px 0px 0px;
			display: flex;
			align-items: center;
			color: getColor(grey900);
			justify-content: space-between;
			box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

			//add a Close button to the left of the header
			.drawer__header__close {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				height: 56px;
				width: 56px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				.drawer__header__close__icon {
					height: 24px;
					width: 24px;
					fill: rgba(253, 106, 106, 1);
				}
			}
			.drawer__header__title {
				margin-left: 16px;
				display: flex;
				align-items: center;
				height: 100%;
				color: #ffffff;
				font-size: 16px;
				font-weight: 700;
			}

			//content of the drawer
		}

		.drawer__body {
			// margin-left: 56px;
			box-shadow: 18px -6px 20px 8px rgba(0, 0, 0, 0.8);
			padding: 16px;
			// overflow-y: auto;
			padding-top: 10px;
			height: 100%;
			overflow-x: hidden;
			overflow-y: scroll;
			padding-bottom: 40%;
			scroll-behavior: smooth;
			background-color: #ffffff;
			.drawer__body__items {
				margin-top: 16px;
				box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				border: 1px solid getColor(grey300);
				border-radius: 6px;
				margin-bottom: 16px;
				.items__item {
					display: flex;
					padding-inline: 10px;
					padding-block: 10px;
					border-bottom: 1px solid getColor(grey300);
					flex-direction: column;
					&:last-child {
						border-bottom: none;
					}
					.items__left {
						display: flex;

						.item__image {
							height: 84px;
							width: 84px;
							border-radius: 10px;
							position: relative;
							img {
								height: 84px;
								width: 84px;
								border-radius: 10px;
								object-fit: contain;
								border: 1px solid color(grey300);
							}
							.item__discount {
								position: absolute;
								background-image: url('../../../assets/svg/pdiscount.svg');
								background-size: contain;
								background-repeat: no-repeat;
								height: 25px;
								width: 25px;
								top: 0;
								left: 10px;
								span {
									position: relative;
									font-size: 8px;
									color: white;
									font-weight: 600;
									display: flex;
									align-items: center;
									justify-content: center;
									height: 100%;
									width: 100%;
									text-align: center;
								}
							}
						}
						.item__details {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							height: 100%;
							margin-left: 16px;
							gap: 4px;
							min-height: 84px;
							width: 50%;

							.item_name {
								font-size: 12px;
								font-weight: 500;
								color: getColor(grey900);
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								max-width: 140px;
								text-decoration: none;
							}
						}
					}
					.tems__right {
						display: flex;
						flex-direction: column;
						gap: 8px;
						align-items: flex-end;
						height: 84px;
						//flex end
					}
				}
			}

			//scrollbar
			&::-webkit-scrollbar {
				width: 5px;
			}
		}
		.empty {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			height: 100%;
			font-size: 16px;
			font-weight: 700;
			color: #55584d;
			margin-top: 50%;

			.empty_text {
				position: relative;
				//after a horainzontal line
				&:after {
					position: absolute;
					content: '';
					width: 20px;
					height: 1px;
					background-color: #55584d;
					top: 50%;
					right: -25px;
				}
				&:before {
					position: absolute;
					content: '';
					width: 20px;
					height: 1px;
					background-color: #55584d;
					top: 50%;
					left: -25px;
				}
			}
		}
	}
}

.shopping_cart_offer_card {
	margin: 0px 0;
	border: 0.5px dashed color(primary_base);
	background-color: #e7f1f1;
	border-radius: 6px;
	padding: 10px;
	& > div {
		@include itemsCenter;
		font-size: 12px;
		font-weight: 600;
		color: getColor(primary_base);

		svg {
			margin-right: 10px;
			width: 24px;
			height: 24px;
		}
	}
}
.prescriptions {
	display: flex;
	align-items: center;
	gap: 10px;
}
.payment {
	border-radius: 6px;
	border: 1px solid color(grey300);
	background: #fff;

	.payment__item {
		padding-inline: 17px;
		padding-block: 20px;
		border-bottom: 1px solid color(grey300);
		margin-bottom: 14px;
	}
	.payment__item_sep {
		border-bottom: 2px dashed color(grey300);
	}
}
.delivery_options {
	padding: 10px;

	.disabled {
		opacity: 0.8;
	}
	.common {
		@include flexBetween;

		p {
			font-size: 14px;
			margin: 0;
		}

		.option_list {
			display: flex;
			cursor: pointer;

			svg {
				margin-right: 5px;
			}
			.type {
				@include flexColumn;
				.text {
					font-size: 12px;
					color: $greyD;
					font-weight: 400;
					margin: 5px 0;
				}
				.error {
					color: $error;
					font-size: 12px;
					font-weight: 400;
					margin-bottom: 5px;
				}
			}
		}
	}

	.express {
		margin-top: 10px;
	}

	.select_date_time {
		font-size: 12px;
		padding-block: 10px;
		color: $primary;
		justify-content: space-between;
	}
}

.checkout_wraper {
	padding: 10px 16px;
	position: fixed;
	bottom: 0px;
	z-index: 9999;
	width: 380px;
	height: 75px;
	background-color: white;

	align-items: center;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	.checkout {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: getColor(primary);
		border-radius: 5px;
		.checkout__left {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 10px;
		}
	}
}

.delivery_type_regular {
	p {
		background-color: white !important;
		color: getColor(grey900) !important;
		padding: 0px;
		svg {
			width: 20px;
			height: 20px;
			background-color: getColor(primary);
			border-radius: 50%;
			padding: 5px;
		}
		font-size: 12px;
	}
}
.delivery_type_express {
	p {
		background-color: white !important;
		color: getColor(grey900) !important;
		padding: 0px;
		svg {
			width: 20px;
			height: 20px;
			background-color: getColor(grey900);
			border-radius: 50%;
			padding: 5px;
		}
		font-size: 12px;
	}
}
