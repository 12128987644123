.discount_chip {
	width: 85px;
}

.chip {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.chip.testCard {
	padding-right: 10px;
	background-image: url('../../../../assets/svg/labTestDiscount.svg');
}

.chip.cart {
	padding-bottom: 3px;
	background-image: url('../../../../assets/svg/labTestCartDiscount.svg')
}

.discount_chip p {
	color: white;
}

.discount_chip.height_12 {
	height: 12px;
}

.discount_chip.height_15 {
	height: 15px;
}

.discount_chip.height_18 {
	height: 18px;
}

.discount_chip.height_22 {
	height: 22px;
}

.discount_chip.height_30 {
	height: 30px;
}

.discount_chip.height_32 {
	height: 32px;
}

@media (min-width: 768px) and (max-width: 1200px) {
	.chip_text {
		font-size: 10px;
	}

	.discount_chip.height_12 {
		height: 8px;
	}

	.discount_chip.height_15 {
		height: 11px;
	}

	.discount_chip.height_18 {
		height: 14px;
	}

	.discount_chip.height_22 {
		height: 18px;
	}

	.discount_chip.height_30 {
		height: 26px;
	}

	.discount_chip.height_32 {
		height: 28px;
	}

}