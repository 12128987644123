.testDetails_lists_text {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 12px;
	margin-bottom: 24px;
	text-wrap: wrap;
}

.testDetails_lists_text svg {
	flex-shrink: 0;
}

.testDetails_lists_text span {
	color: var(--ltPrimary);
}
.testDetails_lists_text:last-of-type {
	margin-bottom: 0;
}
