.container {
	width: 100%;
	min-width: 300px;
	max-width: 500px;
	max-height: 500px;
	overflow-y: scroll;
	position: relative;
}

.icon_container_wrap,
.button_container,
.cancel_reason_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
	padding: 30px 30px 30px 30px;
}

.other_reason_container {
	padding: 0px 30px 30px 30px;
}

.button_container {
	background-color: #ffffff;
	flex-direction: row;
	justify-content: center;
	gap: 1rem;
	width: 100%;
	padding: 30px 30px 30px 30px;
	position: sticky;
	bottom: 0;
}

.button_container.button_container_orderCancel {
	padding: 10px 30px 10px 30px;
}

.icon_container {
	width: 7.5rem;
	height: 7.5rem;
	background: #fd6a6a1a;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon_container.icon_container_bg {
	background-color: #fefae9;
}

.content_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.content_container:first-child {
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1.75rem;
	color: #1f2937;
	text-align: center;
}
.content_container:last-child {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	color: #1f2937;
	text-align: center;
}

.lists_wrap {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.listItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
