.chip {
	padding: 4px 14px;
	background: #fef0f0;
	color: #fd6a6a;
	border-radius: 22px;
	background: #ebf8fe;
	color: var(--ltPrimary);
	width: fit-content;
	text-transform: capitalize;
}
.chip > p {
	text-wrap: nowrap;
}
.chip.primary {
	background: var(--ltPrimary);
	color: #e8f5f5;
}
.chip.primary p {
	color: #e8f5f5;
}
.chip.blue {
	background: #ebf8fe;
	color: #38bdf8;
}
.chip.error {
	background: #fef0f0;
	color: #fd6a6a;
}
.chip.orange {
	background: #fff4ed;
	color: #fe964a;
}
.chip.yellow {
	background: #fff9eb;
	color: #ffc837;
}
.chip.honeydew {
	background: #e7f7ef;
	color: var(--alerts-success-base, #0caf60);
}

.chip .lg {
	font-size: 16px;
	font-weight: 400;
}
.chip .sm {
	font-size: 12px;
	font-weight: 400;
}
