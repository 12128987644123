.address {
	border: none;
	border-radius: 10px;
	background: #fff;
	flex: 1;
}
.address:hover {
	box-shadow:
		0 4px 6px -1px rgb(0 0 0 / 0.1),
		0 2px 4px -2px rgb(0 0 0 / 0.1);
	cursor: pointer;
}

.address_head {
	border-radius: 10px 10px 0px 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #e8f5f5;
	padding: 12px 10px;
}

.address_head.light {
	background: #f7f7f7;
}

.address_head span {
	display: flex;
	align-items: center;
	gap: 5px;
	color: #1da099;
	cursor: pointer;
}

.address_text {
	display: flex;
	align-items: flex-start;
	gap: 20px;
	color: #1da099;
	padding: 20px;
}

.icon_container {
	padding-top: 8px;
}
