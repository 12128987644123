/*  Modal */

.dialog {
	border: none;
	outline: none;
	background: #ffffff;
	border-radius: 10px;
	padding: 0;
	cursor: default;
	/* overflow: scroll; */
	min-width: 280px;
}

.dialog_head_line {
	height: 1px;
	background: #e8f5f5;
}

.dialog_body {
	max-width: 700px;
}

/* StateFull Modal */

.modal {
	display: none;
	position: fixed;
	z-index: 10001;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	overflow: hidden;
	cursor: default;
}

.modal::-webkit-scrollbar {
	display: none;
}
.modal_body {
	/* transform: translate(70%, 50%); */
	background-color: #fefefe;
	margin: 7% auto;
	border: 1px solid #888;
	width: fit-content;
	border-radius: 10px;
	overflow: auto;
	max-width: 700px;
}
.modal.open {
	display: block;
}
