.cart_wrap {
	padding: 0px;
}

.cart_apply_promo_btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	margin-top: 20px;
	border-radius: 10px;
	border: 1px solid #1da099;
	padding: 8px 14px;
	cursor: pointer;
}

.cart_apply_promo_btn_left {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	color: #1da099;
}

.cart_apply_promo_btn_right {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	color: #fe964a;
}

.cart_testcards_wrap,
.cart_checkout_details {
	border: none;
	border-radius: 10px;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	background: #fff;
	margin-bottom: 20px;
}

.coupon_container {
	padding: 11px 15px 10px 15px;
}

.coupon_container > p {
	color: var(--ltGreyscale600);
	font-size: 14px;
	font-weight: 500;
	line-height: 160%;
	text-decoration: underline;
	cursor: pointer;
}

.cart_checkout_details_item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 11px 15px 9px;
	font-size: 14px;
}

.cart_checkout_discount {
	border-radius: 10px;
	border: 0.5px dashed #1da099;
	background: #e7f1f1;
	padding: 11px 9px;
	margin-top: 15px;
	margin-bottom: 40px;
}

.cart_checkout_discount_item {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-bottom: 5px;
}

.cart_checkout_discount_item_icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: #fe964a;
	border: 2px solid #f5f4eb;
	color: #ffffff;
}

.cart_checkout_discount_item p {
	color: #1da099;
}

.cart_checkout {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	margin-top: 20px;
}

.cart_checkout div p:first-child {
	color: #1da099;
}

.cart_checkout_details_item_smaple_discount {
	display: flex;
	align-items: center;
	gap: 10px;
}

.sample_collection_fee_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
}

.order_address_wrap {
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	border: none;
	border-radius: 10px;
	margin: 16px 0px;
}

/* .cart_checkout_details_item_smaple_discount p:nth-child(1) {
  color: #a0aec0;
  text-decoration: line-through;
} */

.cart_checkout_details_item_smaple_discount p:nth-child(2) {
	color: #1da099;
}
.addressModal {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	max-width: 500px;
}

.cash_applied_text {
	font-size: 14px;
}

/* Checkbox */
.cart_checkout_hardcopy,
.popular_diagnostic_image_wrap {
	border-radius: 10px;
	border: 1px solid var(--ltGreyscale300);
	padding: 10px 13px;
	margin-bottom: 20px;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.cart_checkout_bottom span {
	color: var(--ltPrimary);
}
.cart_checkout_hardcopy_checkbox {
	display: flex;
	gap: 10px;
	align-items: center;
}
.cart_checkout_hardcopy_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--ltErrorBase);
	margin-bottom: 20px;
}
.cart_checkout_hardcopy > p {
	color: #323b49;
}
.cart_checkout_paymethod {
	border-radius: 10px;
	/* overflow: hidden; */
	border: 1px solid var(--ltGreyscale300);
	margin-bottom: 20px;
}
.cart_checkout_paymethod_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--ltErrorBase);
	background: #fef0f0;
	padding: 14px 17px;
}
.cart_checkout_paymethod_body {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 20px;
	gap: 16px;
}
.cart_checkout_paymethod_img {
	cursor: pointer;
	transform: scale(1);
	transition: transform 0.15s ease;
}
.cart_checkout_paymethod_img:hover {
	transform: scale(1.05);
}

.cart_checkout_payment_note {
	width: 100%;
	height: 42px;
	border-radius: 5px;
	background: #fff0f0;
	padding: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	color: var(--ltErrorBase);
	font-size: 14px;
	font-weight: 400;
	line-height: 19.2px;
}
