@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
$light: #fff;
$dark: #000;
$l_warning: red;
$danger: $error;
$grey: $un_gray;
.search_warp {
	display: flex;
	align-items: center;
	padding-inline-start: 20px;
	width: 100%;
	font-weight: 200;
	position: relative;
	.top__middle__search__select {
		height: 44px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		background-color: getColor(grey100);
		border: none;
		outline: none;
		width: auto;
		padding: 5px 10px;
		font-size: 16px;
		color: getColor(grey600);
		border-top: 1px solid $grey100 !important;
		border-bottom: 1px solid $grey100 !important;
		border-left: 1px solid $grey100 !important;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		select {
			position: absolute;
			left: 30px;
			height: 44px;
			opacity: 0;
			width: auto;
		}
		.top__middle__search__select__icon {
			fill: getColor(grey600);
		}
		.top__middle__search__select__option {
			color: getColor(grey600);
			font-weight: 400 !important;
			text-transform: capitalize;
		}
		.top__middle__search__select__label {
			color: getColor(grey600);
			font-weight: 500 !important;
			text-transform: capitalize;
		}
	}
	.input_block {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		.top__middle__search__input {
			outline: none;
			padding: 5px 20px;
			font-size: 16px;
			color: getColor(grey900);
			background-color: getColor(primary_bg);
			height: 44px;
			width: 100%;
			border: 1px solid getColor(primary_bg);
			font-weight: 400;

			&::placeholder {
				font-size: 16px;
				color: getColor(grey600);
				font-weight: 300;
			}
		}

		.top__middle__search__button {
			width: 45px;
			height: 44px;
			min-width: 45px;
			background-color: $primary;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.search_results {
			position: absolute;
			top: 124%;
			width: 100%;
			min-height: 100px;
			z-index: 2;
			background-color: #fff;
			border-radius: 0 0 10px 10px;
			padding: 20px;
		}
		.overlay {
			display: block;
			position: fixed; // Fixed position to cover the whole viewport
			top: 70px;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.5); // semi-transparent background
			z-index: 1; // Adjust z-index if needed, it should be below search_results but above everything else
		}
	}

	.top__middle__search__input {
		&:focus {
			border-top: 1.5px solid $primary !important;
			border-bottom: 1.5px solid $primary !important;
		}
	}
	.top__middle__search__select__focused {
		border-top: 1.5px solid $primary !important;
		border-bottom: 1.5px solid $primary !important;
		border-left: 1.5px solid $primary !important;
	}
}

@media (min-width: 576px) {
	.search_warp {
		width: 100%;
	}
}
@media (min-width: 760px) {
	.search_warp {
		width: 100%;
	}
}
@media (min-width: 800px) {
	.search_warp {
		width: 100%;
	}
}
@media (min-width: 1024px) {
	.search_warp {
		width: 100%;
	}
}

@media (min-width: 1100px) {
	.search_warp {
		width: 100%;
	
	}
}

@media (min-width: 1200px) {
	.search_warp {
		width: 100%;
	}
}
@media (min-width: 1280px) {
	.search_warp {
		width: 100%;
	}
}

@media (min-width: 1400px) {
	.search_warp {
		width: 100%;
	}
}

/* Extra large devices (1600px and up) */
@media (min-width: 1600px) {
	.search_warp {
		width: 100%;
	}
}
