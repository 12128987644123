.popover {
	display: none;
	position: absolute;
	top: 35px;
	right: 0px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	margin-top: 20px;
	z-index: 110;
	max-height: 285px;
	margin-bottom: 20px;
	min-width: 150px;
	max-width: 100%;
	overflow-y: auto;
}
.popover.fullWidth {
	left: 0;
}
.popover.open {
	display: block;
}
