.button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	background: #fe964a;
	color: #fff;
	padding: 12px 20px;
	border-radius: 6px;
	outline: none;
	border: none;
	cursor: pointer;
	max-height: 44px;
}
/* variants */
.button.primary {
	background: var(--ltPrimary);
}
.button.error {
	background: var(--ltErrorLight);
}
.button.outlined {
	background: #fff;
	border: 1px solid #eeeff2;
	color: var(--ltGreyscale600);
}
.button.outlinedError {
	color: var(--ltErrorBase);
	background: #fef0f0;
	border: 1px solid var(--ltErrorLight);
}

/* sizes */
.button.fullWidth {
	width: 100%;
}

.button.lg {
	padding: 12px 56px;
}
.button.sm {
	padding: 8px 26px;
}
.button.xs {
	padding: 3px 12px;
}

.button.xl {
	padding: 16px 56px;
}

/* colors */
.button.primary_base {
	color: var(--ltGreyscale900);
}

.button.primary_light {
	color: #1da099;
	background: #e7f1f1;
	border: 1px solid #1da099;
}
.button i {
	color: inherit;
}

.button:disabled {
	background: var(--ltGreyscale400);
}

/* Other Variants */
.button.secondary {
	border: 1px solid var(--Others-Orange, #fe964a);
	background: rgba(254, 150, 74, 0.1);
	color: var(--Others-Orange, #fe964a);
}

@media (max-width: 1023px) {
	.button {
		padding: 10px 12px;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.button {
		font-size: 12px;
	}
}