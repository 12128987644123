.sampleCollectionAddress {
	border-radius: 10px;
	border: 1px solid #eeeff2;
	padding: 20px;
	background: #fff;
}
.sampleCollectionAddress_wrap {
	background: #f9fafb;
	max-width: 600px;
	padding: 20px;
}
.sampleCollectionAddress_row1 {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 20px;
}
