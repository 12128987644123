@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.footer {
	background: color(grey800);
	color: getColor(white);
	.row {
		padding-top: 70px !important;
	}
	.logo {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.logo__text {
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.title {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 30px;
		font-weight: bold;
	}
	.links {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 20px;
		width: fit-content;
		gap: 10px;
		.links__link {
			margin-bottom: 10px;
			font-size: 16px;
			font-weight: 400;
			color: getColor(white);
			&:hover {
				color: getColor(primary);
			}
		}
	}
	.social {
		display: flex;
		align-items: center;
		margin-top: 20px;
		gap: 10px;
		.social__icon {
			height: 48px;
			width: 48px;
			background-color: getColor(grey700);
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 0.3s ease-in-out;
			color: inherit;
			cursor: pointer;
			&:hover {
				background-color: getColor(primary);
			}
		}
	}
	.solid {
		width: 100%;
		height: 1px;
		background: color(white);
		margin-top: 20px;
	}
	.copyRight {
		margin-top: 20px;

		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-top: 1px solid color(grey700);
	}
}


