@import '@/services/scss/variables.scss';

.container {
	height: 230px;
	background-color: #f5f5f5;
	border-radius: 10px;
	position: relative;
	border: 1px solid getColor(grey300);

	//multiple Sizes
	&.small {
		height: 70px;
		width: 56.6px;
		border-radius: 6px;
	}
	&.medium {
		height: 150px;
		width: 150px;
	}
	&.large {
		height: 200px;
		width: 200px;
	}

	.image {
		height: 100%;
		width: 100%;
		border-radius: 10px;
		object-fit: cover;
	}

	.image_footer {
		position: absolute;
		background-color: #111827;
		bottom: 0;
		height: 50px;
		width: 100%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		cursor: pointer;
		&.small {
			height: 20px;
			width: 100%;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			font-size: 10px;
			font-weight: 500;
		}
		&.medium {
			height: 30px;
			width: 100%;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
		}
		&.large {
			height: 40px;
			width: 100%;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
		}

		// multiple type just checng the background color
		&.success {
			background-color: getColor(primary);
			color: white !important;
		}
		&.danger {
			background-color: getColor(red);
		}
		&.warning {
			background-color: getColor(yellow);
		}
		&.info {
			background-color: getColor(blue);
		}
		&.default {
			background-color: getColor(gray);
		}
	}
}

.image {
	// Add your image styling here
	border-radius: 8px;
	cursor: pointer;
}
.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal {
	border-radius: 20px;
	position: relative;
	img {
		border-radius: 10px;
	}
}

.fullImage {
	max-height: 80vh;
	max-width: 80vw;
	object-fit: contain;
}

.closeButton {
	position: absolute;
	top: -30px;
	right: -5px;
	border: none;
	color: white;
	cursor: pointer;
	border-radius: 5px;
}
