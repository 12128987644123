.cartTestCard {
	padding: 10px;
	border-bottom: 1px solid #eeeff2;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.cartTestCard_container {
	display: flex;
	gap: 10px;
}

.cartTestCard:last-of-type {
	border-bottom: none;
}

.cartTestCard_description {
	width: 100%;
}

.cartTestCard_top {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.cartTestCard_icon {
	position: relative;
	width: 85px;
	height: 85px;
}

.chip_discountPrice {
	width: fit-content;
	position: absolute;
	top: 0;
	left: -9px !important;
}
.cartTestCard_bottom {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 3px;
}

.cartTestCard_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 3px;
}

.testCard_left_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--ltPrimary);
}

.cartTestCard_left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.cartTestCard_body {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}

.cartTestCard_body_price {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

.cartTestCard_body p:nth-child(1),
.cartTestCard_header p:nth-child(1) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.address_error_msg {
	display: flex;
	align-items: center;
	justify-content: center;
}

.address_error_msg > p {
	font-size: 10px;
	font-weight: 500;
}

/* .cartTestCard_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    background: #e8f5f5;
    border-radius: 50%;
    color: var(--ltPrimary);
} */

/* .cartTestCard_icon > i {
    color: #fe964a;
} */

/* 
.cartTestCard_icon_right {
    color: var(--ltGreyscale600);
    cursor: pointer;
}

.dialog_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.dialog_head i {
    color: #718096;
    cursor: pointer;
}

.dialog_head_line {
    height: 1px;
    background: #e8f5f5;
}

.cartTestCard_bottom_discount {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.cartTestCard_bottom_discount p:nth-child(1) {
    color: #a0aec0;
    text-decoration: line-through;
} */
