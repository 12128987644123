.textfield_wrap {
	padding: 8px 12px;
	background: #f9fafb;
	color: #a0aec0;
	border-radius: 8px;
	border: 1px solid var(--ltGreyscale300);
}

.textfield_wrap.textfield_wrap_error {
	border: 1px solid var(--ltErrorDark);
}

.textfield {
	border: none;
	overflow: auto;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	resize: none;
	background: inherit;
	color: #a0aec0;
	width: 100%;
	font-size: 14px;
}
.textfield::placeholder {
	color: #a0aec0;
	font-family: 'Inter', sans-serif;
}

.textfield_wrap p {
	display: flex;
	justify-content: flex-end;
}
