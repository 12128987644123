@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.delivery_type {
	p {
		background-color: getColor(primary_base);
		display: flex;
		align-items: center;
		border-radius: 5px;
		padding: 3px 5px;
		color: getColor(white);
		margin: 0;
		font-size: 12px !important;
		height: 22px;
		font-weight: 500;
		svg {
			color: getColor(white);
			width: 16px;
			height: 16px;
			@include itemsCenter;
			margin-right: 5px;
		}
	}
	.express {
		background-color: getColor(grey700);
		color: getColor(white);
		p {
			color: getColor(white);
		}
		svg {
			color: getColor(warning);
		}
	}
	.regular {
		background-color: #323b49;
		color: white;
		svg {
			color: $primary;
		}
	}
}

.circle_icon {
	p {
		max-width: 30px;
		@include itemsCenter;
		justify-content: center;
		padding: 0;
		height: 20px;
		width: 20px;
		border-radius: 100%;
		padding: 3px;

		svg {
			margin-right: 0;
		}
	}
}

.text {
	@include itemsCenter;
	font-size: 11px;
	span {
		display: block;
		margin-left: 5px;
	}
}
