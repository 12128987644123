.checkboxOverride {
	position: relative;
	width: 25px;
}
.checkboxOverride input[type='checkbox'] {
	opacity: 0;
}
.checkboxOverride input[type='checkbox']:checked + label:after {
	opacity: 1;
}
.checkboxOverride input[type='checkbox']:checked + label {
	background: var(--ltPrimary);
	opacity: 1;
}
.checkboxOverride label {
	border: 2px solid #a0aec0;
	cursor: pointer;
	height: 25px;
	width: 25px;
	left: 0;
	position: absolute;
	top: 0;
	border-radius: 3px;
}
.checkboxOverride label:after {
	border-style: none none solid solid;
	content: '';
	height: 5px;
	width: 9px;
	left: 5px;
	top: 5px;
	opacity: 0;
	position: absolute;
	-ms-transform: rotate(-45deg); /* IE 9 */
	-webkit-transform: rotate(-45deg); /* Safari and Chrome */
	transform: rotate(-45deg);
	color: #fff;
}
