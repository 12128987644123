.testCard_patient_btn {
	width: 140px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

@media (min-width: 768px) and (max-width: 1200px) {
	.testCard_patient_btn {
		width: 110px;
	}
}
