@import '@/services/scss/variables.scss';
.upload_prescription {
	width: 100%;
	height: 70px;
	border-radius: 6px;
	border: 1px dashed getColor(primary);
	background: #fff;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	padding-inline: 20px;
	gap: 16px;
	cursor: pointer;
	.upload_prescription__icon {
		svg {
			width: 46px;
			height: 46px;
			fill: color(primary);
		}
	}
	.upload_prescription__text {
		display: flex;
		flex-direction: column;
		gap: 4px;
		span {
			font-size: 16px;
			font-weight: 500;
		}
		small {
			font-size: 14px;
			font-weight: 400;
			color: getColor(grey600);
		}
	}
}
