.sideDrawer {
	position: fixed;
	right: 0;
	top: 0;
	background: #ffffff;
	height: 100%;
	width: 378px;
	transform: translateX(378px);
	transition: transform 0.3s ease;
	box-shadow: -15px 0px 15px 0px rgba(29, 160, 153, 0.05);
	z-index: 100;
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
}

.sideDrawer.open {
	transform: translateX(0);
	z-index: 99999;
	top: 0px;
}

.sideDrawer::-webkit-scrollbar {
	display: none;
}

.sideDrawer_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-bottom: 1px solid #eeeff2;
}

.sideDrawer_head i {
	cursor: pointer;
	color: var(--ltErrorDark);
}

.sideDrawer_close_icon {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	right: 0;
	top: 70px;
	background: var(--others-white, #fff);
	height: 48px;
	width: 48px;
	z-index: 100000;
	/* transform: translateX(0); */
	translate: all 0.3s ease-in-out;
	transform: translateX(377px);
	transition: transform 0.3s ease;
	box-shadow: -15px 0px 15px 0px rgba(29, 160, 153, 0.05);
}

.sideDrawer_close_icon i {
	cursor: pointer;
	color: var(--ltErrorDark);
}

.sideDrawer_close_icon.open {
	transform: translateX(-377px);
}
