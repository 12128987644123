//main
$primary: #0e7673;
$primary100: #ccf8e7;
$primary200: #9cf1d8;
$primary300: #64d5be;
$primary400: #3aac9f;

$secondary: #ff0000;
$secondary100: #fffae9;
$secondary200: #fff6d3;
$secondary300: #ffeca7;
$secondary400: #ffde65;

//alerts
$success: #22c55e;
$successDark: #0ba259;
$successBase: #0caf60;
$successLight: #0caf60;

$success100: #55c790;
$success200: #0caf60;

$warning: #ffd023;
$warning100: #ffde65;
$warning200: #e6bb20;

$error: #fd6a6a;
$error100: #fd8181;
$error200: #fd4f4f;

//additional color
$white: #ffffff;
$dark: #111827;
$purple: #8c62ff;

$red: #ff0000;

//grey_scale
$greyD: #718096;

$orange: #fe964a;

$grey50: #fafafa;
$grey100: #f7fafc;
$grey200: #edf2f7;
$grey300: #e2e8f0;
$grey400: #cbd5e0;
$grey500: #a0aec0;
$grey600: #718096;
$grey700: #4a5568;
$grey800: #1f2937;
$grey900: #111827;
$skyblue: #79c1e0;

//Unknown Colors
$un_gray: #f9fafb;
$colors: (
	base: #0e7673,
	primary_base: #0e7673,
	primary: #0e7673,
	primary100: #ccf8e7,
	primary200: #9cf1d8,
	primary300: #64d5be,
	primary400: #3aac9f,
	secondary: #ffd023,
	secondary100: #fffae9,
	secondary200: #fff6d3,
	secondary300: #ffeca7,
	secondary400: #ffde65,

	success: #22c55e,
	successDark: #0ba259,
	successBase: #0caf60,
	successLight: #0caf60,
	success100: #55c790,
	success200: #0caf60,
	warning: #ffd023,
	warning100: #ffde65,
	warning200: #e6bb20,
	error: #fd6a6a,
	error100: #fd8181,
	error200: #fd4f4f,

	white: #ffffff,
	dark: #111827,
	purple: #8c62ff,
	red: #ff0000,
	blue: #0062ff,

	greyD: #718096,
	orange: #fe964a,
	grey50: #fafafa,
	grey100: #f7fafc,
	grey200: #edf2f7,
	grey300: #eeeff2,
	grey400: #cbd5e0,
	grey500: #a0aec0,
	grey600: #718096,
	grey700: #323b49,
	grey800: #080909,
	grey900: #111827,
	skyblue: #79c1e0,
	un_gray: #f9fafb,
	p_bg: #e5f3f3,

	portage: #8c62ff,
	teal: #2dd4bf,

	//// BG Colors
	primary_bg: #e5f3f3,
	babypink_bg: #f9eaed,
	portage_bg: #eeebfb,
	sky_bg: #e0e9f4,
	error_bg: #f9eaed,
	secondary_bg: #faf6e5,

	// others colors
	p_head: #004bb8,
	tranparent: transparent,
	info: #f5f8ff
);

//container padding
$cp: 0.75rem;
//margin
$default-margin: 1px;
$min-multiplier: 1;
$max-multiplier: 100;

//font-size
$default-font-size: 16px;
$min-font-size-multiplier: 1;
$max-font-size-multiplier: 50;
//colors
$min-border-width: 1;
$max-border-width: 10;

$min-rounded-value: 0;
$max-rounded-value: 9999px;

$min-rounded-value: 0;
$max-rounded-value: 9999px;

// slider Colors

@function getColor($key) {
	@return map-get($colors, $key);
}
