@import '@/services/scss/variables.scss';

.select_container {
	position: relative;
	border: 1px solid getColor(grey300);
	border-radius: 10px;
	background-color: #fff;

	&::after {
		content: '';
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		width: 0;
		height: 0;
		border-left: 5px solid transparent; /* Smaller border for a smaller arrow */
		border-right: 5px solid transparent; /* Smaller border for a smaller arrow */
		border-top: 5px solid getColor(primary); /* Smaller and less sharp arrow */
		border-radius: 10px; /* Attempt to round the corners, effect might be subtle */
	}
}

.select_large {
	font-size: 16px;
	height: 46px;
	padding: 11px 16px;
	color: getColor(grey600);
	font-weight: 300;
}

.select_middle {
	font-size: 14px;
	padding: 8px 10px;
}

.select_small {
	font-size: 12px;
	padding: 6px 8px;
}

.select_selected {
	cursor: pointer;
	width: 100%;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.select_value {
	cursor: pointer;
	display: inline-block;
	color: getColor(grey800);
	font-weight: 400;
}
.current_select {
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 90%;
	display: inline-block;
	color: getColor(grey600);
	font-weight: 400;
}
.select_search {
	border: none;
	width: 100%;
	outline: none;
	box-sizing: border-box;
	background-color: transparent; // to match the background of select_selected
	font-size: 16px;
	color: getColor(grey900);
}
.selected {
	color: getColor(grey900);
	background-color: getColor(primary_bg);
}
.select_options {
	position: absolute;
	top: -20%;
	right: -1px;
	min-width: 200px;
	max-height: 300px;
	overflow-y: auto;
	background-color: white;
	z-index: 999;
	box-shadow:
		0px 5px 5px -3px rgba(0, 0, 0, 0.2),
		0px 8px 10px 1px rgba(0, 0, 0, 0.14),
		0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	animation: slide-down 0.3s ease-in-out;
	transform: scaleY(0);
	transform-origin: top;
	transition:
		transform 0.2s ease-out,
		opacity 0.2s ease-out;
	animation: slide-down 0.3s ease-in-out;
	@keyframes slide-down {
		0% {
			opacity: 0;
			transform: translateY(-10px);
		}
		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}
	//scrollbar
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: getColor(grey500); /* Color of the scroll thumb */
		border-radius: 20px; /* Roundness of the scroll thumb */
	}
}

.select_option {
	padding: 8px 10px;
	cursor: pointer;
	border-bottom: 1px solid color(grey300);
	color: getColor(grey900);
	&:hover {
		background-color: getColor(primary_bg);
	}
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $primary; /* Color of the scroll thumb */
		border-radius: 20px; /* Roundness of the scroll thumb */
	}
	&:last-child {
		border-bottom: none;
	}
}

.select_options-entering,
.select_options-entered {
	opacity: 1;
	transform: scaleY(1);
}

.select_options-exiting,
.select_options-exited {
	opacity: 0;
	transform: scaleY(0);
	animation: slide-up 0.3s ease-in-out;
	@keyframes slide-up {
		0% {
			opacity: 1;
			transform: translateY(0px);
		}
		100% {
			opacity: 0;
			transform: translateY(-10px);
		}
	}
}
