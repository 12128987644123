@import '@/services/scss/variables.scss';

.checkboxWrapper {
	display: flex;
	align-items: center;
	position: relative;

	input[type='checkbox'] {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-color: white;
		margin: 0;
		font-size: 1em;
		width: 20px;
		height: 20px;
		min-width: 20px;
		min-height: 20px;
		border: 1px solid getColor(grey300);
		border-radius: 6px;
		position: relative;
		cursor: pointer;

		&:focus {
			outline: none;
		}

		&:checked {
			background-color: getColor(primary);
			border-color: getColor(primary);
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-100%, -86%) rotate(45deg);
				width: 6px;
				height: 10px;
				border-right: 2px solid white;
				border-bottom: 2px solid white;
				opacity: 1;
				transform-origin: bottom;
			}
		}
	}

	label {
		margin-left: 0.5em;
		text-transform: capitalize;
		&:hover {
			color: getColor(primary);
		}
	}
	.checkboxLabel {
		width: -webkit-fill-available;
		display: flex;
		justify-content: space-between;
		color: getColor(grey700);
		font-weight: 400;

		&:hover {
			color: getColor(primary);
		}
	}
}
