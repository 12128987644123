.emptyCart {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 50%;
}

.empty_image_wrap {
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty_text_container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	padding: 10px;
	border-radius: 6px;
	padding-left: 30px;
	padding-right: 30px;
	margin-bottom: 20px;
}

.empty_text {
	position: relative;
	color: var(--ltGreyscale900);
	font-weight: 500;
}

.empty_text:after {
	position: absolute;
	content: '';
	width: 20px;
	height: 1px;
	background-color: #55584d;
	top: 50%;
	right: -25px;
}
.empty_text:before {
	position: absolute;
	content: '';
	width: 20px;
	height: 1px;
	background-color: #55584d;
	top: 50%;
	left: -25px;
}
