@import '@/services/scss/variables.scss';

.treeSelectContainer {
	position: relative;
	width: 100%;

	.dropdownTrigger {
		display: block;
		padding: 10px;
		background-color: #fff;
		border: 1px solid getColor(grey300);
		border-radius: 10px;
		cursor: pointer;
		text-align: left;
		color: getColor(grey900);
		font-weight: 400;

		&:hover {
			border: 1px solid getColor(primary);
		}

		&:after {
			content: '';
			position: absolute;
			rotate: 0deg;
			top: 52%;
			right: 13px;

			transform: translateY(-50%);
			border: 5px solid transparent; // Adjust size as needed
			border-top-color: #333; // Arrow color
			border-bottom: none;
		}
		// add open class to dropdownTrigger when dropdown is open and change arrow direction
		&.dropdownTriggerOpen {
			&:after {
				right: 10px;
				top: 43%;
				rotate: -90deg;
			}
		}
	}

	.dropdownMenu {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1000;
		padding-inline: 10px;
		width: 100%;
		background: white;
		border: 1px solid getColor(grey300);
		border-top: none; // Seamless transition from trigger to dropdown
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
		max-height: 300px; // Adjust as needed
		overflow-y: auto; // Scrollable if too many items
		border-radius: 10px;
		padding-bottom: 10px;
		.searchInput {
			width: calc(100% - 20px); // Adjust width as necessary
			padding: 10px;
			border: 1px solid #ccc;
			margin: 10px;
			border-radius: 10px;
			&:focus {
				outline: none;
				border: 1px solid getColor(primary);
			}
			&::placeholder {
				color: getColor(grey600);
			}
		}
	}
}

.treeItem {
	padding: 10px;
	cursor: pointer;
	position: relative;
	border-radius: 6px;
	margin: 5px;

	&:hover {
		background-color: getColor(primary_bg);
		color: getColor(primary);
		font-weight: 600;
	}

	&:last-child {
		border-bottom: none;
	}
	.count {
		background-color: #e7f1f1;
		color: getColor(primary);
		border-radius: 18px;
		padding: 2px 5px;
		font-size: 14px;
		height: 24px;
		font-weight: 600;
	}
	&.selected {
		background-color: getColor(primary_bg);
		color: getColor(primary);
		font-weight: 600;
	}

	.treeItemToggle {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.subTree {
	padding-left: 20px;
}
.navigation {
	background-color: getColor(primary_bg);
	color: getColor(primary);
	font-weight: 600;
	padding: 10px;
	border-radius: 6px;
	cursor: pointer;
	margin: 5px;
}
.parentLabel {
	background-color: getColor(primary_bg);
	color: getColor(primary);
	margin: 5px;
	font-weight: 600;
	padding: 10px;
	border-radius: 6px;
	cursor: pointer;
}
