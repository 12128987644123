.radio_button {
	cursor: pointer;
	display: flex;
	gap: 10px;
}

.label {
	color: var(--ltGreyscale900);
}

.radio_button > div > input {
	/* position: absolute; */
	/* margin: 5px; */
	/* padding: 0; */
	/* for mobile accessibility (iOS Label Bug) */
	visibility: hidden;
}
/* .radio-button .label-visible {
  margin-left: 2em;
  margin-bottom: 0;
} */
.fake_radiobutton {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* width: 24px;
  height: 24px; */
	border-radius: 50%;
	border: 2px solid var(--ltPrimary);
	background-color: white;
}
.fake_radiobutton:after {
	content: '';
	display: none;
	position: absolute;
	top: 50%;
	bottom: 50%;
	left: 50%;
	right: 50%;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: var(--ltPrimary);
	transform: translateX(-50%) translateY(-50%);
}

.radio_button > div > input[type='radio']:checked + .fake_radiobutton:after {
	display: block;
}

/* fake_radiobutton Size */
.fake_radiobutton_20px {
	width: 20px;
	height: 20px;
}

.fake_radiobutton_22px {
	width: 22px;
	height: 22px;
}
.fake_radiobutton_24px {
	width: 24px;
	height: 24px;
}

/* label_text Sizes */
.label_14px_600 {
	font-size: 14px;
	font-weight: 600;
	margin-top: 4px;
}

.label_14px_400 {
	font-size: 14px;
	font-weight: 400;
	margin-top: 2px;
}
.label_12px_400 {
	font-size: 12px;
	font-weight: 400;
	margin-top: 2px;
}

/* Media Queries */
@media (min-width: 768px) and (max-width: 1024px) {
	.radio_button {
		gap: 5px;
	}

	/* label_text Sizes */
	.label_14px_600 {
		font-size: 12px;
		font-weight: 600;
		margin-top: 2px;
	}

	.label_14px_400 {
		font-size: 12px;
		font-weight: 400;
		margin-top: 2px;
	}
	.label_12px_400 {
		font-size: 10px;
		font-weight: 400;
		margin-top: 2px;
	}
}

/* @media (max-width: 1400px) {
	.radio_button {
		gap: 5px;
	}

	/* label_text Sizes 
	.label_14px_600 {
		font-size: 14px;
		font-weight: 600;
		margin-top: 4px;
	}

	.label_14px_400 {
		font-size: 14px;
		font-weight: 400;
		margin-top: 2px;
	}
	.label_12px_400 {
		font-size: 12px;
		font-weight: 400;
		margin-top: 2px;
	}
} */
