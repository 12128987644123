.input_wrap {
	width: 100%;
	border-radius: 10px;
}

.input {
	display: flex;
	flex: 1;
	outline: none;
	border-radius: 10px;
	border: none;
	font-weight: 400;
	font-size: 14px;
	color: var(--ltGreyscale600);
	width: 100%;
	padding: 16px;
}

.input::placeholder {
	font-weight: 400;
	font-size: 16px;
	color: var(--ltGreyscale500);
}
.input.outlined {
	border: 1px solid #eeeff2;
}

.input:focus {
	border: 1px solid var(--ltGreyscale900);
}
