/* MODAL */
.checkout {
	padding: 30px;
	max-width: 564px;
	max-height: 70vh;
	overflow-y: scroll;
}

.checkout::-webkit-scrollbar {
	width: 2px;
	background: transparent;
}

.checkout_address {
	border-radius: 10px;
	border: 1px solid #eeeff2;
	background: #fff;
	margin-bottom: 20px;
	overflow: hidden;
}

.checkout_address_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fff;
	padding: 12px 10px;
	border-bottom: 1px solid #eeeff2;
}

.checkout_address_head span {
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
}

.checkout_address_text {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.textfeild {
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 10px;
}

.select_wrap {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
}

.checkout_paymethod {
	border-radius: 10px;
	/* overflow: hidden; */
	border: 1px solid var(--ltGreyscale300);
	margin-bottom: 20px;
}
.checkout_paymethod_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--ltErrorBase);
	background: #fef0f0;
	padding: 14px 17px;
}
.checkout_paymethod_body {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 20px;
	gap: 16px;
}
.checkout_paymethod_img {
	cursor: pointer;
	transform: scale(1);
	transition: transform 0.15s ease;
}
.checkout_paymethod_img:hover {
	transform: scale(1.05);
}

.checkout_payment_note {
	width: 100%;
	height: 42px;
	border-radius: 5px;
	background: #fff0f0;
	padding: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	color: var(--ltErrorBase);
	font-size: 14px;
	font-weight: 400;
	line-height: 19.2px;
}
.timeSchedule_wrap {
	display: flex;
	align-items: center;
	gap: 10px;
	padding-top: 10px;
	padding-bottom: 20px;
	margin-top: 10px;
	overflow-x: scroll;
}

.timeSchedule_wrap::-webkit-scrollbar-thumb {
	background: var(--ltPrimary);
}

.time_slot_btn {
	width: 100%;
	padding: 10px 20px;
	border-radius: 6px;
	border: 1px solid #eeeff2;
	color: #111827;
	font-size: 12px;
	font-weight: 400px;
	line-height: 19.2px;
	text-align: center;
	cursor: pointer;
}

.selected_time_slot {
	background: var(--ltPrimary);
	color: #fff;
	border: none;
}

.time_slot_btn:hover {
	background: var(--ltPrimary);
	color: #fff;
	border: none;
}

.date_time_heading {
	color: var(--ltGreyscale900);
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
}