.testDetails_wrapper {
	padding: 30px;
}

.testDetails_head {
	display: flex;
	gap: 12px;
	align-items: center;
	padding: 30px;
	position: relative;
	border-bottom: 1px solid var(--ltGreyscale200);
}

.testDetails_head div:nth-child(2) {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.testDetails_head div:nth-child(2) > p {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
	padding-right: 15px;
}

.testDetails_icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	background: #e8f5f5;
	border-radius: 50%;
	color: var(--ltPrimary);
}

.testDetails_icon > i {
	color: #fe964a;
}

.testDetails_lists {
	background: var(--ltWhite);
	padding: 30px;
	height: 100%;
}

.testDetails_lists_text {
	display: flex;
	gap: 12px;
	align-items: center;
	margin-bottom: 24px;
}

.testDetails_lists_text span {
	color: var(--ltPrimary);
}

.close_icon_wrap {
	position: absolute;
	top: 30px;
	right: 20px;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 24px;
	color: var(--ltErrorDark);
	cursor: pointer;
}
